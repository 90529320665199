<template>
  <v-container>
    <Filtro :user-id="userId" @search="getLaunch" />
    <v-row>
      <v-col cols="12">
        <base-loading v-if="loading.list" />
      </v-col>
      <v-col v-if="data.length >= 1" col="12">
        <Pie
          id="pieGraph"
          :chart-options="chartOptions"
          :chart-data="chartData"
          :plugins="plugins"
        />
      </v-col>
      <v-col v-if="data.length >= 1" col="12">
        <Bar
          id="barGraph"
          :chart-options="chartBarOptions"
          :chart-data="chartBarData"
        />
      </v-col>
      <v-col cols="12">
        <Tables v-if="data.length >= 1" :data="data" :consultor="consultor" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { Bar, Pie } from 'vue-chartjs/legacy'
import FinancialService from '../../../services/api/financial'
import Tables from '../dashboard/tables.vue'
import Filtro from './filter.vue'

import {
  ArcElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineController,
  Title,
  Tooltip,
} from 'chart.js'
import ChartJsPluginDataLabels from 'chartjs-plugin-datalabels'
import { monthShortLabel } from '../../../utils/dashboard/dashboard'
import { currencyFormatter } from '../../../utils/formatter'
import { renderBarGraph, renderPieGraph } from './graph'

ChartJS.register(
  Title,
  Tooltip,
  Legend,
  ArcElement,
  CategoryScale,
  LineController,
  ChartJsPluginDataLabels,
)

export default {
  name: 'Dashboard',

  components: {
    Tables,
    Filtro,
    Pie,
    Bar,
  },

  props: {
    userId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      monthShortLabel,
      data: [],

      consultor: null,

      loading: {
        list: false,
      },

      chartData: {
        labels: [
          'Alimentação',
          'Combustível',
          'Hospedagem',
          'Pedágio',
          'Honorários',
          'Plano de Saúde',
          'Seguro de Vida',
          'Outros valores',
          'Avarias/Aluguel de Carro',
          'Multa Atraso Envio dos dados',
          'Multas Condutor',
          'Pós Graduação',
          'Outros descontos',
        ],
        datasets: [],
      },

      chartBarData: {
        labels: monthShortLabel,
        datasets: [],
      },

      plugins: [],

      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,

        cutoutPercentage: 50,

        layout: {
          padding: 20,
        },

        plugins: {
          datalabels: {
            color: 'black',
            formatter: () => {
              return ''
            },
            align: 'end',
            rotation: -90,
            position: 'outside',
            offset: 5,
          },
          annotation: {
            annotations: [],
          },
          backgroundColor: '#313a40',
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                const label = tooltipItem.label || ''
                const value = tooltipItem.raw || ''

                return `${label}: ${currencyFormatter(value, 'R$')}`
              },
            },
          },
        },
      },

      chartBarOptions: {
        responsive: true,
        maintainAspectRatio: false,

        scales: {
          y: {
            ticks: {
              callback: function (value) {
                return currencyFormatter(value, 'R$')
              },
            },
          },
        },

        plugins: {
          datalabels: {
            color: 'white',
            formatter: value => {
              if (!value) return ''

              const minData = this.chartBarData.datasets.map(
                item => Math.max(...item.data) * 0.25,
              )

              const min = Math.max(...minData)

              if (value < min) return ''

              return currencyFormatter(value, 'R$')
            },
            align: 'center',
            anchor: 'center',
            rotation: -90,
          },
          annotation: {
            annotations: [],
          },
          backgroundColor: '#313a40',
          tooltip: {
            callbacks: {
              label: function (tooltipItem) {
                const label = tooltipItem.label || ''
                const value = tooltipItem.raw || ''

                return `${label}: ${currencyFormatter(value, 'R$')}`
              },
            },
          },
        },
      },
    }
  },

  methods: {
    async getLaunch(form) {
      const { dataInicio, dataFim, consultor } = form

      this.loading.list = true
      this.data = []

      try {
        const { data } = await FinancialService.list({
          DataFim: dataFim,
          DataInicio: dataInicio,
          id: consultor,
        })

        this.chartData.datasets = renderPieGraph({ data })

        const result = renderBarGraph({ data })

        this.chartBarData.datasets = result

        this.data = data
        this.consultor = form.user.Nome
      } catch (error) {
        this.Toast().fire({
          icon: 'error',
          title: 'Erro ao buscar lançamentos',
        })
      } finally {
        this.loading.list = false
      }
    },
  },
}
</script>

<style>
.v-data-table-header th {
  white-space: nowrap;
}
</style>
