<template>
  <v-card class="ma-3">
    <v-tabs v-model="tab" bg-color="deep-purple-accent-4" centered stacked>
      <v-tab @click="tab = 0">
        <v-icon>mdi-launch</v-icon>
        Lançamentos
      </v-tab>

      <v-tab @click="tab = 1">
        <v-icon>mdi-view-dashboard</v-icon>
        Dashboard
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item>
        <base-loading v-if="loading.list" />
        <crud-list
          v-else
          v-model="list"
          :headers="headers"
          :actions="actions"
          :slots="[
            'item.total',
            'item.debito',
            'item.launch',
            'item.createdAt',
            'item.updatedAt',
          ]"
        >
          <template
            v-if="
              $user.get().role === 'consultor' ||
              $user.get().role === 'admin' ||
              $user.get().role === 'consultoria'
            "
            v-slot:btnCadastro
          >
            <v-row>
              <v-col cols="12" sm="6" md="auto" class="col-xs-2 py-md-0">
                <router-link :to="`financeiro/create`">
                  <v-btn color="success"> Novo lançamento </v-btn>
                </router-link>
              </v-col>
            </v-row>
          </template>
          <template v-slot:[`item.total`]="{ item }">
            {{ item.total ? 'R$ ' + currencyFormatter(item.total) : ' - ' }}
          </template>
          <template v-slot:[`item.debito`]="{ item }">
            {{ 'R$ ' + currencyFormatter(item.debito) }}
          </template>
          <template v-slot:[`item.launch`]="{ item }">
            {{
              item.launch
                ? moment(new Date(item.launch)).utc().format('DD/MM/YYYY')
                : ' - '
            }}
          </template>
          <template v-slot:[`item.createdAt`]="{ item }">
            {{
              item.createdAt
                ? moment(new Date(item.createdAt)).utc().format('DD/MM/YYYY')
                : ' - '
            }}
          </template>
          <template v-slot:[`item.updatedAt`]="{ item }">
            {{
              item.updatedAt
                ? moment(new Date(item.updatedAt)).format('DD/MM/YYYY HH:mm:ss')
                : ' - '
            }}
          </template>
        </crud-list>
      </v-tab-item>

      <v-tab-item>
        <Dashboard :user-id="userId" />
      </v-tab-item>
    </v-tabs-items>

    <DialogFinancial
      :visita="visita"
      :reports="reports"
      :dialog="dialog"
      @hidden="hidden"
    />
  </v-card>
</template>

<script>
import moment from 'moment'
import financialService from '../../../services/api/financial/index'
import reportService from '../../../services/api/reports/index'
import { currencyFormatter } from '../../../utils/formatter'
import DialogFinancial from './DialogFinancial.vue'
import Dashboard from './index.vue'

export default {
  components: {
    DialogFinancial,
    Dashboard,
  },
  data() {
    return {
      loading: {
        list: false,
      },

      tab: 0,

      actions: [
        {
          title: 'Visualizar nota',
          color: 'primary',
          click: item => {
            this.show(item)
          },
          icon: 'mdi-eye',
        },
        {
          title: 'Editar lançamento',
          color: 'yellow darken-3',

          click: item =>
            this.$router.push({
              path: 'financeiro/create',
              query: {
                id: item.id,
              },
            }),

          icon: 'mdi-pencil',
        },
        {
          title: 'Excluir lançamento',
          color: 'red darken-3',
          click: item => this.delete(item),
          icon: 'mdi-delete',
        },
      ],

      headers: [
        {
          text: '',
          sortable: false,
          width: '40px',
          value: 'actions',
          align: 'left',
        },
        {
          text: 'Consultor',
          align: 'left',
          sortable: true,
          value: 'user.name',
          width: 'auto',
          filterable: true,
        },
        {
          text: 'Gastos totais',
          align: 'left',
          sortable: true,
          value: 'total',
          width: 'auto',
          filterable: true,
        },
        {
          text: 'Debitos totais',
          align: 'left',
          sortable: true,
          value: 'debito',
          width: 'auto',
          filterable: true,
        },
        {
          text: 'Número da nota',
          align: 'left',
          sortable: true,
          value: 'numeroNota',
          width: 'auto',
          filterable: true,
        },
        {
          text: 'Data do lançamento',
          align: 'left',
          sortable: true,
          value: 'launch',
          width: 'auto',
          filterable: true,
        },
        {
          text: 'Criação',
          align: 'left',
          sortable: true,
          value: 'createdAt',
          width: 'auto',
          filterable: true,
        },
        {
          text: 'Ultima atualização',
          align: 'left',
          sortable: true,
          value: 'updatedAt',
          width: 'auto',
          filterable: true,
        },
      ],

      list: [],
      reports: [],

      dialog: false,
      visita: {},

      userId: null,
    }
  },

  async created() {
    this.getExternalActions()
    await this.getFinancialConsultor()
  },

  methods: {
    moment,
    currencyFormatter,
    async getFinancialConsultor() {
      this.loading.list = true
      await this.getReports()
      const result = await financialService.all()

      let normalized = result.data.map(item => {
        return {
          ...item,
          total:
            item.alimentacao +
            item.combustivel +
            item.hospedagem +
            item.pedagio +
            item.fees +
            item.healthInsurance +
            item.lifeInsurance +
            item.otherValues,

          debito:
            item.unitedBreakdowns +
            item.fineLateData +
            item.driverFines +
            item.postgraduate +
            item.purchasing +
            item.otherDiscounts,
        }
      })

      normalized = normalized.sort((a, b) => {
        return new Date(b.launch) - new Date(a.launch)
      })

      this.list = normalized
      this.loading.list = false
    },

    async getReports() {
      const result = await reportService.list({ id: null })

      this.reports = result
    },

    show(item) {
      this.visita = item
      this.dialog = true
    },

    hidden() {
      this.dialog = false
    },

    async delete(item) {
      this.Swal.fire({
        title: 'Tem certeza que deseja excluir este lançamento?',
        text: 'Você não poderá desfazer esta ação!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Sim, excluir!',
        cancelButtonText: 'Cancelar',
      }).then(async res => {
        if (res.value) {
          try {
            const result = await financialService.delete({
              id: item.id,
            })

            if (result.success) {
              this.list = this.list.filter(i => i.id !== item.id)

              this.Toast().fire({
                icon: 'success',
                title: 'Lançamento financeiro foi deletado com sucesso!',
              })
            } else {
              this.Swal.fire({
                title: 'Erro!',
                text: 'Não foi possível excluir o lançamento!',
                icon: 'error',
                confirmButtonText: 'Ok',
              })
            }
          } catch (error) {
            this.Swal.fire({
              title: 'Erro!',
              text: 'Não foi possível excluir o lançamento!',
              icon: 'error',
              confirmButtonText: 'Ok',
            })
          }
        }
      })
    },

    getExternalActions() {
      const userId = this.$route.query.userId
      const tab = Number(this.$route.query.tab)

      this.tab = tab || 0
      this.userId = userId || null
    },
  },
}
</script>

<style>
.v-tab--active .v-icon {
  color: #003677 !important;
}
</style>
