<template>
  <v-dialog v-model="dialog" persistent max-width="1000px">
    <v-card class="pa-6">
      <v-list-item-content>
        <v-card-title class="headline mx-auto"
          >Dados do lançamento
        </v-card-title>

        <v-row v-if="!loading" class="pa-3">
          <v-col cols="12" sm="12" lg="12" xl="12" class="col-bordered">
            <span class="key-title">Gastos totais: </span>
            <span class="value-title"
              >R$
              {{
                currencyFormatter(
                  visita.alimentacao +
                    visita.combustivel +
                    visita.hospedagem +
                    visita.pedagio +
                    visita.fees +
                    visita.healthInsurance +
                    visita.lifeInsurance +
                    visita.otherValues,
                )
              }}</span
            >
          </v-col>
          <v-col cols="12" sm="3" lg="3" xl="3" class="col-bordered">
            <span class="key-title">Alimentação: </span>
            <span class="value-title"
              >R$ {{ currencyFormatter(visita.alimentacao) }}</span
            >
          </v-col>
          <v-col cols="12" sm="3" lg="3" xl="3" class="col-bordered">
            <span class="key-title">Combustível: </span>
            <span class="value-title"
              >R$ {{ currencyFormatter(visita.combustivel) }}</span
            >
          </v-col>
          <v-col cols="12" sm="3" lg="3" xl="3" class="col-bordered">
            <span class="key-title">Hospedagem: </span>
            <span class="value-title"
              >R$ {{ currencyFormatter(visita.hospedagem) }}</span
            >
          </v-col>
          <v-col cols="12" sm="3" lg="3" xl="3" class="col-bordered">
            <span class="key-title">Pedágio: </span>
            <span class="value-title"
              >R$ {{ currencyFormatter(visita.pedagio) }}</span
            >
          </v-col>

          <v-col cols="12" sm="3" lg="3" xl="3" class="col-bordered">
            <span class="key-title">Honorários: </span>
            <span class="value-title"
              >R$ {{ currencyFormatter(visita.fees) }}</span
            >
          </v-col>
          <v-col cols="12" sm="3" lg="3" xl="3" class="col-bordered">
            <span class="key-title">Plano de Saúde: </span>
            <span class="value-title"
              >R$ {{ currencyFormatter(visita.lifeInsurance) }}</span
            >
          </v-col>

          <v-col cols="12" sm="3" lg="3" xl="3" class="col-bordered">
            <span class="key-title">Seguro de Vida: </span>
            <span class="value-title"
              >R$ {{ currencyFormatter(visita.healthInsurance) }}</span
            >
          </v-col>

          <v-col cols="12" sm="3" lg="3" xl="3" class="col-bordered">
            <span class="key-title">Outros valores: </span>
            <span class="value-title"
              >R$ {{ currencyFormatter(visita.otherValues) }}</span
            >
          </v-col>
        </v-row>
      </v-list-item-content>

      <v-list-item-content>
        <v-card-title class="headline mx-auto"
          >Debitos do consultor
        </v-card-title>

        <v-row v-if="!loading" class="pa-3">
          <v-col cols="12" sm="12" lg="12" xl="12" class="col-bordered">
            <span class="key-title">Debitos totais: </span>
            <span class="value-title"
              >R$
              {{
                currencyFormatter(
                  visita.unitedBreakdowns +
                    visita.fineLateData +
                    visita.driverFines +
                    visita.postgraduate +
                    visita.purchasing +
                    visita.otherDiscounts,
                )
              }}</span
            >
          </v-col>
          <v-col cols="12" sm="3" lg="3" xl="3" class="col-bordered">
            <span class="key-title">Avarias Aluguel de Carro: </span>
            <span class="value-title"
              >R$ {{ currencyFormatter(visita.unitedBreakdowns) }}</span
            >
          </v-col>
          <v-col cols="12" sm="3" lg="3" xl="3" class="col-bordered">
            <span class="key-title">Multa Atraso Envio dos dados: </span>
            <span class="value-title"
              >R$ {{ currencyFormatter(visita.fineLateData) }}</span
            >
          </v-col>
          <v-col cols="12" sm="3" lg="3" xl="3" class="col-bordered">
            <span class="key-title">Multas Condutor: </span>
            <span class="value-title"
              >R$ {{ currencyFormatter(visita.driverFines) }}</span
            >
          </v-col>
          <v-col cols="12" sm="3" lg="3" xl="3" class="col-bordered">
            <span class="key-title">Pós Graduação: </span>
            <span class="value-title"
              >R$ {{ currencyFormatter(visita.postgraduate) }}</span
            >
          </v-col>
          <v-col cols="12" sm="3" lg="3" xl="3" class="col-bordered">
            <span class="key-title">Compras/Aquisição: </span>
            <span class="value-title"
              >R$ {{ currencyFormatter(visita.purchasing) }}</span
            >
          </v-col>
          <v-col cols="12" sm="3" lg="3" xl="3" class="col-bordered">
            <span class="key-title">Outros descontos: </span>
            <span class="value-title"
              >R$ {{ currencyFormatter(visita.otherDiscounts) }}</span
            >
          </v-col>
        </v-row>
      </v-list-item-content>

      <v-list-item-content>
        <v-card-title class="headline mx-auto">Informações </v-card-title>

        <v-row v-if="!loading" class="pa-3">
          <v-col cols="12" sm="4" lg="4" xl="4" class="col-bordered">
            <span class="key-title">Consultor: </span>
            <span class="value-title">{{ visita.user?.name }}</span>
          </v-col>
          <v-col cols="12" sm="4" lg="4" xl="4" class="col-bordered">
            <span class="key-title">Número da nota: </span>
            <span class="value-title">{{ visita.numeroNota }}</span>
          </v-col>
          <v-col cols="12" sm="4" lg="4" xl="4" class="col-bordered">
            <span class="key-title">Data do lançamento: </span>
            <span class="value-title">{{
              moment(visita.launch).format('DD/MM/YYYY')
            }}</span>
          </v-col>
          <v-col cols="12" sm="4" lg="4" xl="4" class="col-bordered">
            <span class="key-title">Data da criação: </span>
            <span class="value-title">{{
              moment(visita.data).format('DD/MM/YYYY')
            }}</span>
          </v-col>
          <v-col cols="12" sm="4" lg="4" xl="4" class="col-bordered">
            <span class="key-title">Ultima atualização: </span>
            <span class="value-title">{{
              moment(visita.updatedAt).format('DD/MM/YYYY')
            }}</span>
          </v-col>
        </v-row>
      </v-list-item-content>

      <v-card-actions align="">
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="close"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import { currencyFormatter } from '../../../utils/formatter'
export default {
  props: {
    visita: {
      type: Object,
      default: null,
    },

    dialog: {
      type: Boolean,
      default: false,
    },

    reports: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      loading: false,
    }
  },

  created() {
    this.visita.total = this.visita.total
      ? `R$ ${this.currencyFormatter(this.visita.total)}`
      : ' - '
  },

  methods: {
    currencyFormatter,

    close() {
      this.$emit('hidden')
    },
  },
}
</script>
<style scoped>
.key-title {
  font-weight: 500;
  font-size: '16px';
}

.value-title {
  font-size: '16px';
}

.col-bordered {
  border: solid 1px #ccc;
}
</style>
