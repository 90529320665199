var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-card',{staticClass:"ma-3"},[_c('v-tabs',{attrs:{"bg-color":"deep-purple-accent-4","centered":"","stacked":""},model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab',{on:{"click":function($event){_vm.tab = 0}}},[_c('v-icon',[_vm._v("mdi-launch")]),_vm._v(" Lançamentos ")],1),_c('v-tab',{on:{"click":function($event){_vm.tab = 1}}},[_c('v-icon',[_vm._v("mdi-view-dashboard")]),_vm._v(" Dashboard ")],1)],1),_c('v-tabs-items',{model:{value:(_vm.tab),callback:function ($$v) {_vm.tab=$$v},expression:"tab"}},[_c('v-tab-item',[(_vm.loading.list)?_c('base-loading'):_c('crud-list',{attrs:{"headers":_vm.headers,"actions":_vm.actions,"slots":[
          'item.total',
          'item.debito',
          'item.launch',
          'item.createdAt',
          'item.updatedAt',
        ]},scopedSlots:_vm._u([(
            _vm.$user.get().role === 'consultor' ||
            _vm.$user.get().role === 'admin' ||
            _vm.$user.get().role === 'consultoria'
          )?{key:"btnCadastro",fn:function(){return [_c('v-row',[_c('v-col',{staticClass:"col-xs-2 py-md-0",attrs:{"cols":"12","sm":"6","md":"auto"}},[_c('router-link',{attrs:{"to":`financeiro/create`}},[_c('v-btn',{attrs:{"color":"success"}},[_vm._v(" Novo lançamento ")])],1)],1)],1)]},proxy:true}:null,{key:`item.total`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.total ? 'R$ ' + _vm.currencyFormatter(item.total) : ' - ')+" ")]}},{key:`item.debito`,fn:function({ item }){return [_vm._v(" "+_vm._s('R$ ' + _vm.currencyFormatter(item.debito))+" ")]}},{key:`item.launch`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.launch ? _vm.moment(new Date(item.launch)).utc().format('DD/MM/YYYY') : ' - ')+" ")]}},{key:`item.createdAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.createdAt ? _vm.moment(new Date(item.createdAt)).utc().format('DD/MM/YYYY') : ' - ')+" ")]}},{key:`item.updatedAt`,fn:function({ item }){return [_vm._v(" "+_vm._s(item.updatedAt ? _vm.moment(new Date(item.updatedAt)).format('DD/MM/YYYY HH:mm:ss') : ' - ')+" ")]}}],null,true),model:{value:(_vm.list),callback:function ($$v) {_vm.list=$$v},expression:"list"}})],1),_c('v-tab-item',[_c('Dashboard',{attrs:{"user-id":_vm.userId}})],1)],1),_c('DialogFinancial',{attrs:{"visita":_vm.visita,"reports":_vm.reports,"dialog":_vm.dialog},on:{"hidden":_vm.hidden}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }