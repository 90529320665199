/* eslint-disable */
import HtmlToCanvas from 'html2canvas'
import jsPDF from 'jspdf'
import autoTable from 'jspdf-autotable'
import moment from 'moment'
import _ from 'underscore'
import { ENV_APP } from '../../../../env'
import { monthsNames } from '../../../utils/dashboard/dashboard'
import { daysInMonth } from '../../../utils/formatter'
import { grubByMonth } from './dashboard'

const currencyFormatter = (number, prefix = null) => {
  if (!number || number == 0 || number == '0') return ''

  const res = new Intl.NumberFormat('pt-BR', {
    minimumFractionDigits: 2,
    currency: 'BRL',
    maximumFractionDigits: 2,
  }).format(String(number))

  if (prefix) {
    return prefix + ' ' + res
  }

  return res
}

export const headerDoc = ({ doc, config }) => {
  const { title, logo, startY } = config

  const headerLogo = new Image()

  headerLogo.src = logo

  doc.addImage(headerLogo, 'PNG', 13, 10, 20, 20)
  doc.setFontSize(config.largefontSize).setFont('helvetica', 'bold')

  doc.text(
    title,
    config.tableLimit / 2 - doc.getTextWidth(title) / 2 + 20,
    20 + 30,
  )

  doc.setFontSize(config.fontSize).setFont('helvetica', 'normal')

  doc
    .setFont('helvetica', 'bold')
    .setFontSize(12)
    .text('Consultor: ', 13, startY + 40)
    .setFont('helvetica', 'normal')
    .setFontSize(config.fontSize)

  doc
    .setFont('helvetica', 'normal')
    .setFontSize(12)
    .text(config.consultor, 35, startY + 40)
    .setFont('helvetica', 'normal')
    .setFontSize(config.fontSize)
}

export const addTables = ({ doc, tables, header, colorHeader }) => {
  let startY = 10

  tables.forEach(table => {
    if (startY === 0) startY = 20

    if (table[0]) {
      doc
        .setFont('helvetica', 'bold')
        .setFontSize(12)
        .setTextColor(112, 112, 112)
        .text(table[0], 15, startY, { maxWidth: 100 })

      startY += 5
    } else {
      startY += 5
    }

    const result = months(table[1])

    if (!result && result[5].length < 1) return

    Object.entries(result).forEach((data, index) => {
      const dateTime = new moment(data[1][0].launch)

      const find = data[1].filter(item => {
        if (item.alimentacao) return item
        if (item.combustivel) return item
        if (item.hospedagem) return item
        if (item.pedagio) return item
        if (item.fees) return item
        if (item.healthInsurance) return item
        if (item.lifeInsurance) return item
        if (item.otherValues) return item

        return null
      })

      if (find.length < 1) return

      startY += 2

      const days = daysInMonth(dateTime.year(), dateTime.month() + 1)

      const daysObj = _.range(1, days + 1).map(day => {
        return {
          value: 'day' + day,
          text: day,
          align: 'start',
        }
      })

      daysObj.push({ value: 'total', text: 'Total', align: 'start' })

      let dataObj = daysObj.map(day => {
        const find = data[1].filter(item => {
          const dayItem = Number(new moment(item.launch).format('DD'))

          if (dayItem === day.text) {
            return item
          }
        })

        if (find) {
          const alimentacao =
            find.reduce((acc, item) => {
              return acc + item.alimentacao
            }, 0) || 0

          const combustivel =
            find.reduce((acc, item) => {
              return acc + item.combustivel
            }, 0) || 0

          const hospedagem =
            find.reduce((acc, item) => {
              return acc + item.hospedagem
            }, 0) || 0

          const fees =
            find.reduce((acc, item) => {
              return acc + item.fees
            }, 0) || 0

          const pedagio =
            find.reduce((acc, item) => {
              return acc + item.pedagio
            }, 0) || 0

          const healthInsurance =
            find.reduce((acc, item) => {
              return acc + item.healthInsurance
            }, 0) || 0

          const lifeInsurance =
            find.reduce((acc, item) => {
              return acc + item.lifeInsurance
            }, 0) || 0

          const otherValues =
            find.reduce((acc, item) => {
              return acc + item.otherValues
            }, 0) || 0

          const total =
            alimentacao +
            combustivel +
            hospedagem +
            pedagio +
            fees +
            healthInsurance +
            lifeInsurance +
            otherValues

          return [
            day.text,
            alimentacao,
            combustivel,
            hospedagem,
            pedagio,
            fees,
            healthInsurance,
            lifeInsurance,
            otherValues,
            total,
          ]
        }

        return [day.text]
      })

      // sum total by rows
      const sums = dataObj.reduce(
        (acc, item) => {
          acc.sumAlimentacao += parseFloat(item[1])
          acc.sumCombustivel += parseFloat(item[2])
          acc.sumHospedagem += parseFloat(item[3])
          acc.sumPedagio += parseFloat(item[4])
          acc.fees += parseFloat(item[5])
          acc.healthInsurance += parseFloat(item[6])
          acc.lifeInsurance += parseFloat(item[7])
          acc.otherValues += parseFloat(item[8])
          return acc
        },
        {
          sumAlimentacao: 0,
          sumCombustivel: 0,
          sumHospedagem: 0,
          sumPedagio: 0,
          fees: 0,
          healthInsurance: 0,
          lifeInsurance: 0,
          otherValues: 0,
        },
      )

      const sumTotal = dataObj
        .slice(0, dataObj.length - 1)
        .reduce((acc, item) => {
          return acc + parseFloat(item[9])
        }, 0)

      sums.sumTotal = sumTotal

      // format all values
      dataObj = dataObj.map(item => {
        const result = item.slice(1, item.length).map((value, index) => {
          if (!value) return ''
          return currencyFormatter(value, 'R$')
        })

        return [item[0], ...result]
      })

      dataObj[dataObj.length - 1][1] = currencyFormatter(
        sums.sumAlimentacao,
        'R$',
      )
      dataObj[dataObj.length - 1][2] = currencyFormatter(
        sums.sumCombustivel,
        'R$',
      )
      dataObj[dataObj.length - 1][3] = currencyFormatter(
        sums.sumHospedagem,
        'R$',
      )
      dataObj[dataObj.length - 1][4] = currencyFormatter(sums.sumPedagio, 'R$')
      dataObj[dataObj.length - 1][5] = currencyFormatter(sums.fees, 'R$')
      dataObj[dataObj.length - 1][6] = currencyFormatter(
        sums.healthInsurance,
        'R$',
      )
      dataObj[dataObj.length - 1][7] = currencyFormatter(
        sums.lifeInsurance,
        'R$',
      )
      dataObj[dataObj.length - 1][8] = currencyFormatter(sums.otherValues, 'R$')

      dataObj[dataObj.length - 1][9] = currencyFormatter(sums.sumTotal, 'R$')

      const head = header.map(item => {
        if (typeof item === 'object') {
          return item.name ?? item.text
        }
        return item
      })

      autoTable(doc, {
        startY: startY,
        head: [head.flat()],
        body: dataObj,
        theme: 'striped',
        align: 'left',
        headStyles: { fillColor: colorHeader },
        willDrawPage: docPage => {
          const currentY = docPage.cursor.y

          doc
            .setFont('helvetica', 'normal')
            .setFontSize(10)
            .setTextColor(184, 184, 204)
            .text(
              new moment(dateTime).format('MMMM [de] YYYY'),
              15,
              currentY - 2,
            )
        },
        didDrawPage: docPage => {
          if (docPage.pageCount === 2) doc.addPage()
        },
      })
    })
  })
}

export const addDebitTables = ({ doc, tables, header, colorHeader }) => {
  let startY = 20

  tables.forEach((table, indexTable) => {
    if (startY === 0) startY = 20

    if (table[0]) {
      doc
        .setFont('helvetica', 'bold')
        .setFontSize(12)
        .setTextColor(112, 112, 112)
        .text(table[0], 15, startY, { maxWidth: 100 })

      startY += 5
    } else {
      startY += 5
    }

    const result = months(table[1])

    if (!result && result[5].length < 1) return

    Object.entries(result).forEach((data, index) => {
      const dateTime = new Date(data[1][0].launch)

      const find = data[1].filter(item => {
        if (item.unitedBreakdowns) return item
        if (item.fineLateData) return item
        if (item.driverFines) return item
        if (item.postgraduate) return item
        if (item.purchasing) return item
        if (item.otherDiscounts) return item

        return null
      })

      if (find.length < 1) return

      doc
        .setFont('helvetica', 'normal')
        .setFontSize(10)
        .setTextColor(184, 184, 204)
        .text(moment(dateTime).format('MMMM [de] YYYY'), 15, startY, {
          maxWidth: 100,
        })

      startY += 2

      const days = daysInMonth(dateTime.getFullYear(), dateTime.getMonth() + 1)

      const daysObj = _.range(1, days + 1).map(day => {
        return {
          value: 'day' + day,
          text: day,
          align: 'start',
        }
      })

      daysObj.push({ value: 'total', text: 'Total', align: 'start' })

      let dataObj = daysObj.map(day => {
        const find = data[1].filter(item => {
          const dayItem = Number(moment(item.launch).format('DD'))

          if (dayItem === day.text) {
            return item
          }
        })

        if (find) {
          const unitedBreakdowns =
            find.reduce((acc, item) => {
              return acc + item.unitedBreakdowns
            }, 0) || 0

          const fineLateData =
            find.reduce((acc, item) => {
              return acc + item.fineLateData
            }, 0) || 0

          const driverFines =
            find.reduce((acc, item) => {
              return acc + item.driverFines
            }, 0) || 0

          const postgraduate =
            find.reduce((acc, item) => {
              return acc + item.postgraduate
            }, 0) || 0

          const purchasing =
            find.reduce((acc, item) => {
              return acc + item.purchasing
            }, 0) || 0

          const otherDiscounts =
            find.reduce((acc, item) => {
              return acc + item.otherDiscounts
            }, 0) || 0

          const total =
            unitedBreakdowns +
            fineLateData +
            driverFines +
            postgraduate +
            purchasing +
            otherDiscounts

          return [
            day.text,
            unitedBreakdowns,
            fineLateData,
            driverFines,
            postgraduate,
            purchasing,
            otherDiscounts,
            total,
          ]
        }

        return [day.text]
      })

      // sum total by rows
      const sums = dataObj.reduce(
        (acc, item) => {
          acc.unitedBreakdowns += parseFloat(item[1])
          acc.fineLateData += parseFloat(item[2])
          acc.driverFines += parseFloat(item[3])
          acc.postgraduate += parseFloat(item[4])
          acc.purchasing += parseFloat(item[5])
          acc.otherDiscounts += parseFloat(item[6])
          return acc
        },
        {
          unitedBreakdowns: 0,
          fineLateData: 0,
          driverFines: 0,
          postgraduate: 0,
          purchasing: 0,
          otherDiscounts: 0,
        },
      )

      const sumTotal = dataObj
        .slice(0, dataObj.length - 1)
        .reduce((acc, item) => {
          return acc + parseFloat(item[7])
        }, 0)

      sums.sumTotal = sumTotal

      // format all values
      dataObj = dataObj.map(item => {
        const result = item.slice(1, item.length).map((value, index) => {
          return currencyFormatter(value, 'R$')
        })

        return [item[0], ...result]
      })

      dataObj[dataObj.length - 1][1] = currencyFormatter(
        sums.unitedBreakdowns,
        'R$',
      )
      dataObj[dataObj.length - 1][2] = currencyFormatter(
        sums.fineLateData,
        'R$',
      )
      dataObj[dataObj.length - 1][3] = currencyFormatter(sums.driverFines, 'R$')
      dataObj[dataObj.length - 1][4] = currencyFormatter(
        sums.postgraduate,
        'R$',
      )
      dataObj[dataObj.length - 1][5] = currencyFormatter(sums.purchasing, 'R$')
      dataObj[dataObj.length - 1][6] = currencyFormatter(
        sums.otherDiscounts,
        'R$',
      )
      dataObj[dataObj.length - 1][7] = currencyFormatter(sums.sumTotal, 'R$')

      const head = header.map(item => {
        if (typeof item === 'object') {
          return item.name ?? item.text
        }
        return item
      })

      autoTable(doc, {
        startY: startY,
        head: [head.flat()],
        body: dataObj,
        theme: 'striped',
        align: 'left',
        headStyles: { fillColor: colorHeader },
        willDrawPage: function (docPage) {
          const currentY = docPage.cursor.y

          doc
            .setFont('helvetica', 'normal')
            .setFontSize(10)
            .setTextColor(184, 184, 204)
            .text(
              new moment(dateTime).format('MMMM [de] YYYY'),
              15,
              currentY - 2,
            )
        },
        didDrawPage: docPage => {
          if (
            docPage.pageCount === 2 &&
            !(
              indexTable === table.length - 1 &&
              Object.entries(result).length - 1 === index
            )
          )
            doc.addPage()
        },
      })
    })
  })
}

export const create = async ({ config, label, labels, values }) => {
  return new Promise(async function async(resolve, reject) {
    const doc = new jsPDF('l')

    let startY = 0

    config.startY = startY
    config.tableLimit =
      doc.internal.pageSize.width - config.margin - config.margin
    config.heightLimit = doc.internal.pageSize.height - config.margin

    headerDoc({ doc, config })

    const header = [label, ...labels, { value: 'total', text: 'Total' }]

    try {
      let canvas = await HtmlToCanvas(document.querySelector('#pieGraph'))
      let dataURL = canvas.toDataURL()

      doc.addImage(dataURL, 'PNG', 55, config.startY + 65, 180, 120)

      doc.addPage()

      canvas = await HtmlToCanvas(document.querySelector('#barGraph'))
      dataURL = canvas.toDataURL()

      doc.addImage(dataURL, 'PNG', 55, config.startY + 40, 200, 140)

      doc.addPage()

      addTables({
        doc,
        tables: values,
        header: [...header.slice(0, 9), header[header.length - 1]],
        colorHeader: [31, 73, 125],
      })

      doc
        .setFontSize(config.largefontSize)
        .setFont('helvetica', 'bold')
        .setTextColor(0, 0, 0)
      const title = 'Relatório de Despesas - Débitos'
      doc.text(
        title,
        config.tableLimit / 2 - doc.getTextWidth(title) / 2 + 20,
        20 + 30,
      )

      addDebitTables({
        doc,
        tables: values,
        header: [header[0], ...header.slice(9, header.length)],
        colorHeader: [187, 98, 104],
      })

      footer({ doc, config })

      save({ doc, filename: config.title })
    } catch (error) {
      reject(error)
    }

    resolve()
  })
}

const months = data => {
  const result = grubByMonth({ data })
  return result
}

export const footer = ({ doc, config }) => {
  const { heightLimit } = config
  const pageCount = doc.internal.getNumberOfPages()
  const docWidth = doc.internal.pageSize.width
  const date = footerTitle()

  for (let i = 0; i < pageCount; i++) {
    doc.setPage(i)
    let pageCurrent = doc.internal.getCurrentPageInfo().pageNumber
    doc.setFontSize(12)
    const text =
      date +
      '       ' +
      `${ENV_APP.relUri}` +
      '        Pag. ' +
      pageCurrent +
      ' de ' +
      pageCount

    doc.setTextColor(112, 112, 112)
    doc.setFontSize(9)
    doc.text(text, docWidth / 2, heightLimit + 15, 'center')
  }
}

export const footerTitle = () => {
  const now = new Date()
  const str =
    'Impresso em ' +
    now.getDate() +
    ' de ' +
    monthsNames[now.getMonth()] +
    ' de ' +
    now.getFullYear() +
    ' às ' +
    moment(now).format('DD/MM/YYYY')

  return str
}

export const save = ({ doc, filename = '' }) => {
  const name = filename.replace(/\s/g, '-').toLowerCase()
  filename = `${name}-${new Date().getTime()}.pdf`

  const result = doc.save(filename)

  return result
}
