const { daysInMonth, currencyFormatter } = require('../../../utils/formatter')
import moment from 'moment'
import _ from 'underscore'

export const header = ({ label, dateTime }) => {
  const days = daysInMonth(dateTime.getFullYear(), dateTime.getMonth() + 1)

  const depesas = {
    value: 'label',
    text: label,
    align: 'start',
    width: '100px',
    fixed: true,
  }

  const daysObj = _.range(1, days + 1).map(day => {
    return {
      value: 'day' + day,
      text: day,
      align: 'start',
    }
  })

  const header = [depesas, ...daysObj, { value: 'total', text: 'Total' }]

  return header
}

export const renderDays = ({ label, data, item }) => {
  const tmp = {}

  Object.entries(data).forEach(([key, value]) => {
    const total = value.reduce((acc, value) => {
      return acc + value[item]
    }, 0)

    tmp['day' + parseInt(key.split('-')[2], 10)] = total
  })

  // total geral
  tmp['total'] = Object.values(tmp).reduce((acc, value) => {
    return acc + value
  })

  tmp['label'] = label

  return tmp
}
export const render = ({ rowsIndetify, data }) => {
  const grupByDays = _.groupBy(data, 'launch')

  const all = []

  rowsIndetify.forEach(item => {
    const tmp = renderDays({
      label: item.name,
      item: item.value,
      data: grupByDays,
    })

    all.push(tmp)
  })

  // sum total by days
  const total = {}

  all.forEach(item => {
    Object.entries(item).forEach(([key, value]) => {
      if (key !== 'label') {
        if (!value) return
        if (
          item['label'] == 'Compras/Aquisição' ||
          item['label'] == 'Outros descontos' ||
          item['label'] == 'Pós Graduação' ||
          item['label'] == 'Multas Condutor' ||
          item['label'] == 'Multa Atraso Envio dos dados' ||
          item['label'] == 'Avarias/Aluguel de Carro'
        ) {
          total[key] = total[key] - value
        } else {
          total[key] = total[key] ? total[key] + value : value
        }
      }
    })
  })

  all.push({
    label: 'Total',
    class: 'blue-row',
    ...total,
  })

  // sum total columns
  Object.entries(total).forEach(([key, value]) => {
    if (!value) return
    total[key] = currencyFormatter(value, 'R$')
  })

  all.forEach(item => {
    Object.entries(item).forEach(([key, value]) => {
      if (key !== 'label' && key !== 'class') {
        if (value) item[key] = currencyFormatter(value, 'R$')
        else item[key] = ''
      }
    })
  })

  return all
}

export const grubByYears = ({ data }) => {
  const result = _.groupBy(data, item => {
    return new Date(item.launch).getFullYear()
  })

  return result
}

export const grubByMonth = ({ data }) => {
  const result = _.groupBy(data, item => {
    return new moment(item.launch).get('month')
  })

  return result
}
