<template>
  <div>
    <v-list
      v-for="(item, index) in years"
      :key="index"
      lines="two"
      class="dashboard"
    >
      <v-subheader :key="index" :name="index">
        <h3 class="text-disabled">{{ item[0] }}</h3>
      </v-subheader>

      <v-divider></v-divider>
      <v-row class="mt-20">
        <v-col
          v-for="(result, key) in months(item[1])"
          :key="key"
          cols="12"
          class="tables"
        >
          <p class="disabled">
            {{ moment(result[0].launch).format('MMMM [de] YYYY') }}
          </p>
          <v-divider></v-divider>
          <div>
            <TableGraph
              :items="render({ data: result, label, rowsIndetify: labels })"
              :headers="header({ label, dateTime: new Date(result[0].launch) })"
              :items-per-page="15"
              :v-fixed-columns="true"
            />
          </div>
        </v-col>
      </v-row>
    </v-list>

    <v-row>
      <v-col cols="12">
        <v-btn color="primary" dark @click="autotable"
          ><v-icon left>mdi-printer</v-icon> Download PDF</v-btn
        >
      </v-col>
    </v-row>
  </div>
</template>

<script>
import TableGraph from '../../../components/base/TableGraph.vue'
import { CONFIG } from '../../../services/reports/dashboard/DashboardBase'
import { create } from './autotable'
import { grubByMonth, grubByYears, header, render } from './dashboard'

export default {
  name: 'Tables',

  components: {
    TableGraph,
  },

  props: {
    data: {
      type: Array,
      default: () => [],
      required: true,
    },

    consultor: {
      type: String,
      default: '',
      required: true,
    },
  },

  data: () => ({
    CONFIG,
    headers: [],
    values: [],

    years: [],

    label: 'Despesas/dia',

    labels: [
      { name: 'Alimentação', value: 'alimentacao' },
      { name: 'Combustível', value: 'combustivel' },
      { name: 'Hospedagem', value: 'hospedagem' },
      { name: 'Pedágio', value: 'pedagio' },
      { name: 'Honorários', value: 'fees' },
      { name: 'Plano de Saúde', value: 'healthInsurance' },
      { name: 'Seguro de Vida ', value: 'lifeInsurance' },
      { name: 'Outros valores', value: 'otherValues' },

      { name: 'Avarias/Aluguel de Carro', value: 'unitedBreakdowns' },
      { name: 'Multa Atraso Envio dos dados', value: 'fineLateData' },
      { name: 'Multas Condutor', value: 'driverFines' },
      { name: 'Pós Graduação', value: 'Postgraduate' },
      { name: 'Compras/Aquisição', value: 'purchasing' },
      { name: 'Outros descontos', value: 'otherDiscounts' },
    ],
  }),

  watch: {
    data() {
      this.run()
    },
  },

  created() {
    this.run()
  },

  methods: {
    render,
    grubByYears,
    grubByMonth,
    header,
    create,

    autotable() {
      const config = {
        ...CONFIG,
        title: 'Relatório de despesas',
        consultor: this.consultor,
      }

      create({
        config,
        label: this.label,
        labels: this.labels,
        values: this.years,
      })
    },

    run() {
      this.years = Object.entries(this.grubByYears({ data: this.data }))
    },

    months(data) {
      const result = this.grubByMonth({ data })
      return result
    },
  },
}
</script>

<style>
.disabled {
  color: #9e9e9e;
}

.v-data-table .v-data-table__fixed {
  min-width: 100px;
}
</style>
