import moment from 'moment'
import _ from 'underscore'
import { GRAPH_COLORS } from '../../../utils/constants'
import { monthShortLabel } from '../../../utils/dashboard/dashboard'

export const renderBarGraph = ({ data }) => {
  const dataset = []

  const grubyYear = _.groupBy(data, item => moment(item.launch).year())

  Object.keys(grubyYear).forEach((year, index) => {
    const grubyMonth = _.groupBy(grubyYear[year], item =>
      moment(item.launch).month(),
    )

    const monthsValues = Object.values(monthShortLabel).map((_, index) => {
      const month = grubyMonth[index]

      if (!month) return 0

      let total = 0

      month.forEach(item => {
        if (!item) return
        total += item.alimentacao || 0
        total += item.combustivel || 0
        total += item.hospedagem || 0
        total += item.pedagio || 0
        total += item.fees || 0
        total += item.healthInsurance || 0
        total += item.lifeInsurance || 0
        total += item.otherValues || 0
        total += item.unitedBreakdowns || 0
        total += item.fineLateData || 0
        total += item.driverFines || 0
        total += item.Postgraduate || 0
        total += item.purchasing || 0
        total += item.otherDiscounts || 0
      })

      return total
    })

    dataset.push({
      type: 'bar',
      label: `${year} - Gastos mensais`,
      backgroundColor: GRAPH_COLORS[index],
      data: monthsValues,
    })
  })

  return dataset
}

export const renderPieGraph = ({ data }) => {
  return [
    {
      backgroundColor: GRAPH_COLORS,
      data: [
        data.reduce((acc, curr) => acc + curr.alimentacao, 0),
        data.reduce((acc, curr) => acc + curr.combustivel, 0),
        data.reduce((acc, curr) => acc + curr.hospedagem, 0),
        data.reduce((acc, curr) => acc + curr.pedagio, 0),
        data.reduce((acc, curr) => acc + curr.fees, 0),
        data.reduce((acc, curr) => acc + curr.healthInsurance, 0),
        data.reduce((acc, curr) => acc + curr.lifeInsurance, 0),
        data.reduce((acc, curr) => acc + curr.otherValues, 0),
        data.reduce((acc, curr) => acc + curr.unitedBreakdowns, 0),
        data.reduce((acc, curr) => acc + curr.fineLateData, 0),
        data.reduce((acc, curr) => acc + curr.driverFines, 0),
        data.reduce((acc, curr) => acc + curr.Postgraduate, 0),
        data.reduce((acc, curr) => acc + curr.purchasing, 0),
        data.reduce((acc, curr) => acc + curr.otherDiscounts, 0),
      ],
    },
  ]
}
